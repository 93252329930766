import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box } from "@mui/material";
import { Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import StyleContent from "../../../components/Global/StyleContent";
import { getPMStatus } from "../../../Api/Report/GetData";
import dayjs from "dayjs";
import { toRupiah } from "../../../components/Global/Grid/DetailGrid";

const PreventivStatus = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getPMStatus();
        setData(res);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
    setIsLoading(false);
  }, []);

  const columns = [
    {
      title: 'No',
      dataIndex: 'key',
      width: 100,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'PM Number',
      dataIndex: 'PMNumber',
      sorter: (a, b) => a.PMNumber.localeCompare(b.PMNumber),
    },
    {
      title: 'Request By',
      dataIndex: 'EmpName',
      sorter: (a, b) => a.EmpName.localeCompare(b.EmpName),
    },
    {
      title: 'Preference Type',
      dataIndex: 'PMType',
      sorter: (a, b) => a.PMType.localeCompare(b.PMType),
    },
    {
      title: 'Preference Name',
      dataIndex: 'TypeName',
      sorter: (a, b) => a.TypeName.localeCompare(b.TypeName),
    },
    {
      title: 'Facility Name',
      dataIndex: 'FacilityName',
      sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
      title: 'Building Name',
      dataIndex: 'BuildingName',
      sorter: (a, b) => a.BuildingName.localeCompare(b.BuildingName),
      render: (value) => value ? value : '-'
    },
    {
      title: 'Location Name',
      dataIndex: 'LocationName',
      sorter: (a, b) => a.LocationName.localeCompare(b.LocationName),
      render: (value) => value ? value : '-'
    },
    {
      title: 'Schedule Type',
      dataIndex: 'ScheduleType',
      sorter: (a, b) => a.ScheduleType.localeCompare(b.ScheduleType),
    },
    {
      title: 'Duration To Complete',
      dataIndex: 'DayToComplete',
      sorter: (a, b) => a.DayToComplete.localeCompare(b.DayToComplete),
      render: (value) => <p>{`${value} ${value > 1 ? 'Hours' : 'Hour'}`}</p>
    },
    {
      title: 'Estimation Cost',
      dataIndex: 'EstimationCost',
      sorter: (a, b) => a.EstimationCost.localeCompare(b.EstimationCost),
      render: (value) => <p>{toRupiah(value)}</p>
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      sorter: (a, b) => a.Status.localeCompare(b.Status),
      render: (value) =>
        value ? value : '-'
    },
  ];

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some((val) =>
      val && val.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  return (
    <StyleContent>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Header
          title="PREVENRIVE STATUS"
          subtitle="All Preventive Status"
        />
      </Box>

      <SearchData
        filterValue={searchText}
        handleFilterChange={handleSearch}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filteredData}
        // rowSelection={data.length === 0 ? null : rowSelection}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: 'max-content' }}
      />

    </StyleContent>
  );
};

export default PreventivStatus