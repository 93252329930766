import React, { useState, useEffect, useCallback } from "react";
import Header from "../../../components/Header";
import { Box, Typography } from "@mui/material";
import { Space, Table, Tabs } from "antd";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import SearchData from "../../global/components/FeatureTable/SearchData";
import { getMPMainTrasaction } from "../Api/GetData";
import DataMPRE from "./MP_RE";
import DataMPPart from "./MP_Part";
import DataMPDoc from "./MP_Doc";
import DataMPCT from "./MP_CT";
import EditMPTransaction from "./edit";
import DeleteMPTransaction from "./delete";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import dayjs from "dayjs";
import {
    AddAuthorityIdx,
    DeleteAuthorityIdx,
    EditAuthorityIdx,
    FacilityAuthority,
    PrintAuthorityIdx
} from "../../../components/Global/Authority";
import { Link } from "react-router-dom";

const RecordDetail = ({ children }) => {
    return (
        <div
            style={{
                display: "flex",
                gap: "8px",
                marginBottom: "14px",
                borderBottom: '2px solid #00000015',
                padding: "10px 0"
            }}
        >
            {children}
            {/* <Typography variant="h5" fontWeight={600}>Description : </Typography>
        <Typography variant="h6">{record.MPTransaction.Description}</Typography> */}
        </div>
    )
}

const MPTransaction = () => {

    const myAddAuthIdx = AddAuthorityIdx();
    const myEditAuthIdx = EditAuthorityIdx();
    const myDeleteAuthIdx = DeleteAuthorityIdx();
    const myPrintAuthIdx = PrintAuthorityIdx();
    const myFacilityAuth = FacilityAuthority();

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await getMPMainTrasaction(myFacilityAuth);
            setData(response);
        } catch (error) {
            console.log(error.response.data);
        }
        setIsLoading(false)
    }, [myFacilityAuth]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const expandedRowRender = (record) => {

        return (
            <Box sx={{ margin: "10px" }}>
                {record.MPTransaction.Description !== '' ?
                    <RecordDetail>
                        <Typography variant="h5" fontWeight={600}>Description : </Typography>
                        <Typography variant="h6">{record.MPTransaction.Description}</Typography>
                    </RecordDetail>
                    : ''
                }
                {record.MPTransaction.Comments !== '' ?
                    <RecordDetail>
                        <Typography variant="h5" fontWeight={600}>Comments : </Typography>
                        <Typography variant="h6">{record.MPTransaction.Comments}</Typography>
                    </RecordDetail>
                    : ''
                }
                <Tabs
                    // onChange={onChange}
                    type="card"
                    items={[
                        {
                            label: 'Resource',
                            key: 'resource',
                            children: <DataMPRE record={record.MPTransaction} />,
                        },
                        {
                            label: 'Part',
                            key: 'Part',
                            children: <DataMPPart record={record.MPTransaction} />,
                        },
                        {
                            label: 'Document',
                            key: 'document',
                            children: <DataMPDoc record={record.MPTransaction} />,
                        },
                        {
                            label: 'Contact',
                            key: 'contact',
                            children: <DataMPCT record={record.MPTransaction} />,
                        },
                    ]}
                />
            </Box>
        );
    };


    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            width: 100,
            fixed: 'left',
            sorter: (a, b) => a.key - b.key,
        },
        {
            title: 'MP Number',
            dataIndex: ['MPTransaction', 'MPNumber'],
            sorter: (a, b) => a.MPTransaction.MPNumber.localeCompare(b.MPTransaction.MPNumber),
            render: (value, record) =>
                <Link
                    to={`/transaction/maintenanceprocess/form?action=view&faccode=${record.MPTransaction.FacilityCode}&trannumber=${record.MPTransaction.MPNumber}`}
                    style={{
                        color: '#0A0B88'
                    }}
                >
                    <p style={{ fontWeight: "bold" }}>{value}</p>
                </Link>
        },
        {
            title: 'Facility Name',
            dataIndex: 'FacilityName',
            sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
        },
        {
            title: 'MP Date',
            dataIndex: ['MPTransaction', 'MPDate'],
            sorter: (a, b) => a.MPTransaction.MPDate.localeCompare(b.MPTransaction.MPDate),
            render: (value) => <p>{dayjs(value).format('YYYY-MM-DD')}</p>
        },
        {
            title: 'MP Status',
            dataIndex: ['MPTransaction', 'MPStatus'],
            sorter: (a, b) => a.MPTransaction.MPStatus.localeCompare(b.MPTransaction.MPStatus),
        },
        {
            title: 'MP Type',
            dataIndex: ['MPTransaction', 'MPType'],
            sorter: (a, b) => a.MPTransaction.MPType.localeCompare(b.MPTransaction.MPType),
        },
        {
            title: 'PM Number',
            dataIndex: ['MPTransaction', 'PMNumber'],
            sorter: (a, b) => a.MPTransaction.PMNumber.localeCompare(b.MPTransaction.PMNumber),
        },
        {
            title: 'Due Date',
            dataIndex: ['MPTransaction', 'DueDate'],
            sorter: (a, b) => a.MPTransaction.DueDate.localeCompare(b.MPTransaction.DueDate),
            render: (value) => <p>{dayjs(value).format('YYYY-MM-DD HH:mm:ss')}</p>
        },
        {
            title: 'Current Meter Reading',
            dataIndex: ['MPTransaction', 'CurrentMeterReading'],
            sorter: (a, b) => a.MPTransaction.CurrentMeterReading.localeCompare(b.MPTransaction.CurrentMeterReading),
        },
        {
            title: 'Start Schedule Date',
            dataIndex: ['MPTransaction', 'StartScheduleDate'],
            sorter: (a, b) => a.MPTransaction.StartScheduleDate.localeCompare(b.MPTransaction.StartScheduleDate),
            render: (value) => <p>{dayjs(value).format('YYYY-MM-DD HH:mm:ss')}</p>
        },
        {
            title: 'Finish Schedule Date',
            dataIndex: ['MPTransaction', 'FinishScheduleDate'],
            sorter: (a, b) => a.MPTransaction.FinishScheduleDate.localeCompare(b.MPTransaction.FinishScheduleDate),
            render: (value) => <p>{dayjs(value).format('YYYY-MM-DD HH:mm:ss')}</p>
        },
        {
            title: 'Location Name',
            dataIndex: 'LocationName',
            sorter: (a, b) => a.LocationName.localeCompare(b.LocationName),
        },
        {
            title: 'Work Category',
            dataIndex: 'WorkCatName',
            sorter: (a, b) => a.WorkCatName.localeCompare(b.WorkCatName),
        },
        {
            title: 'Status',
            dataIndex: ['MPTransaction', 'AppStatus'],
            sorter: (a, b) => a.MPTransaction.AppStatus.localeCompare(b.MPTransaction.AppStatus),
            render: (_, record) => {
                if (!record.MPTransaction.AppStatus) {
                    return "-";
                }
                return record.MPTransaction.AppStatus;
            }
        },
    ];

    if (myEditAuthIdx > 0 || myDeleteAuthIdx > 0) {
        columns.push({
            title: 'Action',
            key: 'Action',
            fixed: 'right',
            width: 100,
            render: (_, record) => {
                if (
                    record.MPTransaction.TranStatus !== 0
                ) {
                    return null;
                }
                return (
                    <Space>
                        {myEditAuthIdx > 0 && (
                            <EditMPTransaction data={record} onEdit={fetchData} />
                        )}
                        {myDeleteAuthIdx > 0 && (
                            <DeleteMPTransaction data={record.MPTransaction} onDelete={fetchData} />
                        )}
                    </Space>
                )
            },
        },
        )
    }

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = data.filter((item) =>
        Object.values(item).some(
            (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    const [selectedRows, setSelectedRows] = useState([]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
            // console.log(selectedRows);
        },
    };

    const columnsExport = columns
        .filter((item) => item.key !== "Action" && item.key !== "key")
        .map((item) => {

            if (item.title === "Active") {
                return {
                    title: item.title,
                    key: item.dataIndex,
                    render: (record) => (record.MPTransaction.IsSuspend ? "Inactive" : "Active"),
                };
            }

            return {
                title: item.title,
                key: item.dataIndex,
            };
        });

    const processExportData = (data) =>
        data.map((item) => ({
            ...item,
            MPTransaction: {
                ...item.MPTransaction,
                IsSuspend: item.MPTransaction.IsSuspend ? "Inactive" : "Active",
            }
        }));

    return (
        <StyleContent>
            <StyleGridHeader>
                <Header
                    title="MAINTENANCE PROCESS"
                    subtitle="All data a Maintenance Process"
                />
                <Box display="flex" justifyContent="end" gap="8px">
                    {myPrintAuthIdx > 0 && (
                        <ExportData
                            data={processExportData(selectedRows.length > 0 ? selectedRows : data)}
                            columns={columnsExport}
                            name="MP Transaction"
                        />
                    )}

                    {myAddAuthIdx > 0 && (
                        <ButtonAddNew
                            link="form"
                        />
                    )}
                </Box>
            </StyleGridHeader>

            <SearchData
                filterValue={searchText}
                handleFilterChange={handleSearch}
            />

            <Table
                loading={isLoading}
                columns={columns}
                dataSource={filteredData}
                rowSelection={data.length === 0 ? null : rowSelection}
                expandable={{
                    expandedRowRender,
                }}
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                scroll={{ x: 'max-content' }}
            />

        </StyleContent>
    );
};

export default MPTransaction