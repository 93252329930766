import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box } from "@mui/material";
import { Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import StyleContent from "../../../components/Global/StyleContent";
import { getDMStatus } from "../../../Api/Report/GetData";
import dayjs from "dayjs";

const DemandStatus = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getDMStatus();
        setData(res);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
    setIsLoading(false);
  }, []);


  const columns = [
    {
      title: 'No',
      dataIndex: 'key',
      width: 100,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'DM Number',
      dataIndex: 'DMNumber',
      sorter: (a, b) => a.DMNumber.localeCompare(b.DMNumber),
    },
    {
      title: 'DM Date',
      dataIndex: 'DMDate',
      sorter: (a, b) => a.DMDate.localeCompare(b.DMDate),
      render: (date) => <p>{dayjs(date).format('YYYY-MM-DD')}</p>
    },
    {
      title: 'DM Status',
      dataIndex: 'DMStatus',
      sorter: (a, b) => a.DMStatus.localeCompare(b.DMStatus),
    },
    {
      title: 'Preference Type',
      dataIndex: 'DMType',
      sorter: (a, b) => a.DMType.localeCompare(b.DMType),
    },
    {
      title: 'Preference Name',
      dataIndex: 'TranTypeRefName',
      sorter: (a, b) => a.TranTypeRefName.localeCompare(b.TranTypeRefName),
    },
    {
      title: 'Facility Name',
      dataIndex: 'FacilityName',
      sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
      title: 'Building Name',
      dataIndex: 'BuildingName',
      sorter: (a, b) => a.BuildingName.localeCompare(b.BuildingName),
      render: (value) => value ? value : '-'
    },
    {
      title: 'Location Name',
      dataIndex: 'LocationName',
      sorter: (a, b) => a.LocationName.localeCompare(b.LocationName),
      render: (value) => value ? value : '-'
    },
    {
      title: 'Work Category',
      dataIndex: 'CatName',
      sorter: (a, b) => a.CatName.localeCompare(b.CatName),
    },
    {
      title: 'Work SubCategory',
      dataIndex: 'SubCatName',
      sorter: (a, b) => a.SubCatName.localeCompare(b.SubCatName),
    },
    {
      title: 'Reschedule Date',
      dataIndex: 'RescheduleDate',
      sorter: (a, b) => a.RescheduleDate.localeCompare(b.RescheduleDate),
      render: (date) => <p>{dayjs(date).format('YYYY-MM-DD')}</p>
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      sorter: (a, b) => a.Status.localeCompare(b.Status),
      render: (value) =>
        value ? value : '-'
    },
  ];

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some((val) =>
      val && val.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  return (
    <StyleContent>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Header
          title="DEMAND STATUS"
          subtitle="All Demand Status"
        />
      </Box>

      <SearchData
        filterValue={searchText}
        handleFilterChange={handleSearch}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filteredData}
        // rowSelection={data.length === 0 ? null : rowSelection}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: 'max-content' }}
      />

    </StyleContent>
  );
};

export default DemandStatus