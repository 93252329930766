import dayjs from "dayjs";
import { toRupiah } from "../../../components/Global/Grid/DetailGrid";
import { Link } from "react-router-dom";

export const columnsFacility = [
    {
        key: 'FacilityCode',
        title: 'Facility Code',
        dataIndex: 'FacilityCode',
        width: 150,
        fixed: 'left',
        sorter: (a, b) => a.FacilityCode.localeCompare(b.FacilityCode),
    },
    {
        key: 'FacilityName',
        title: 'Facility Name',
        dataIndex: 'FacilityName',
        sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
        key: 'Address',
        title: 'Address',
        dataIndex: 'Address',
        width: 400,
        sorter: (a, b) => a.Address.localeCompare(b.Address),
    },
    {
        key: 'City',
        title: 'City',
        dataIndex: 'City',
        sorter: (a, b) => a.City.localeCompare(b.City),
    },
    {
        key: 'Country',
        title: 'Country',
        dataIndex: 'Country',
        sorter: (a, b) => a.Country.localeCompare(b.Country),
    },
    {
        key: 'Phone1',
        title: 'Phone Number 1',
        dataIndex: 'Phone1',
        sorter: (a, b) => a.Phone1.localeCompare(b.Phone1),
    },
    {
        key: 'Phone2',
        title: 'Phone Number 2',
        dataIndex: 'Phone2',
        sorter: (a, b) => a.Phone2.localeCompare(b.Phone2),
    },
    {
        key: 'Fax',
        title: 'Fax',
        dataIndex: 'Fax',
        sorter: (a, b) => a.Fax.localeCompare(b.Fax),
    },
    {
        key: 'ContactPerson',
        title: 'Contact Person',
        dataIndex: 'ContactPerson',
        sorter: (a, b) => a.ContactPerson.localeCompare(b.ContactPerson),
    },
];



export const columnsPM = [
    {
        title: 'PM Number',
        dataIndex: ['PMTransaction', 'PMNumber'],
        sorter: (a, b) => a.PMTransaction.PMNumber.localeCompare(b.PMTransaction.PMNumber),
        render: (value, record) =>
            <Link
                to={`/transaction/pmconfiguration/form?action=view&faccode=${record.PMTransaction.FacilityCode}&trannumber=${record.PMTransaction.PMNumber}`}
                style={{
                    color: '#0A0B88'
                }}
            >
                <p style={{ fontWeight: "bold" }}>{value}</p>
            </Link>
    },
    {
        title: 'FacilityName',
        dataIndex: 'FacilityName',
        sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
        title: 'PM Name',
        dataIndex: ['PMTransaction', 'PMName'],
        sorter: (a, b) => a.PMTransaction.PMName.localeCompare(b.PMTransaction.PMName),
    },
    {
        title: 'PM Date',
        dataIndex: ['PMTransaction', 'PMDate'],
        sorter: (a, b) => a.PMTransaction.PMDate.localeCompare(b.PMTransaction.PMDate),
        render: (date) => <p>{dayjs(date).format('YYYY-MM-DD')}</p>
    },
    {
        title: 'Location Name',
        dataIndex: 'LocationName',
        sorter: (a, b) => a.LocationName.localeCompare(b.LocationName),
        render: (_, record) => {
            if (!record.LocationName) {
                return "-";
            }
            return record.LocationName;
        }
    },
    {
        title: 'PM Type',
        dataIndex: ['PMTransaction', 'PMType'],
        sorter: (a, b) => a.PMTransaction.PMType.localeCompare(b.PMTransaction.PMType),
    },
    {
        title: 'Reference Name',
        dataIndex: 'PMTypeRefName',
        sorter: (a, b) => a.PMTypeRefName.localeCompare(b.PMTypeRefName),
    },
    {
        title: 'Work Category',
        dataIndex: 'WorkCategory',
        sorter: (a, b) => a.WorkCategory.localeCompare(b.WorkCategory),
    },
    // {
    //     title: 'Work SubCategory',
    //     dataIndex: 'WorkSubCategory',
    //     sorter: (a, b) => a.WorkSubCategory.localeCompare(b.WorkSubCategory),
    // },
    {
        title: 'Schedule Type',
        dataIndex: ['PMTransaction', 'ScheduleType'],
        sorter: (a, b) => a.PMTransaction.ScheduleType.localeCompare(b.PMTransaction.ScheduleType),
    },
    {
        title: 'Schedule Start',
        dataIndex: ['PMTransaction', 'ScheduleStart'],
        sorter: (a, b) => a.PMTransaction.ScheduleStart.localeCompare(b.PMTransaction.ScheduleStart),
        render: (value) => <p>{dayjs(value).format(`YYYY-MM-DD`)}</p>
    },
    {
        title: 'Schedule End',
        dataIndex: ['PMTransaction', 'ScheduleEnd'],
        sorter: (a, b) => a.PMTransaction.ScheduleEnd.localeCompare(b.PMTransaction.ScheduleEnd),
        render: (value) => <p>{dayjs(value).format(`YYYY-MM-DD`)}</p>
    },
    {
        title: 'Duration To Complete',
        dataIndex: ['PMTransaction', 'DayToComplete'],
        sorter: (a, b) => a.PMTransaction.DayToComplete.localeCompare(b.PMTransaction.DayToComplete),
        render: (value) => <p>{value} Hour</p>
    },
    {
        title: 'Estimation Cost',
        dataIndex: ['PMTransaction', 'EstimationCost'],
        sorter: (a, b) => a.PMTransaction.EstimationCost.localeCompare(b.PMTransaction.EstimationCost),
        render: (value) => <p>{toRupiah(value)}</p>
    },
];


export const columnsPMSchedule = [
    {
        title: 'Schedule Code',
        dataIndex: 'ScheduleCode',
        key: 'ScheduleCode',
    },
    {
        title: 'Schedule Name',
        dataIndex: 'ScheduleName',
        key: 'ScheduleName',
    },
]