const DetailGrid = ({ children }) => {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                padding: "10px 0"
            }}
        >
            {children}
        </div>
    )
}

export default DetailGrid


export const toRupiah = (amount) => {
    return Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 2
    }).format(amount)
}