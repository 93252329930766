import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box, Tooltip, Typography } from "@mui/material";
import EditVendor from "./edit";
import DeleteVendor from "./delete";
import { Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import ExportData from "../../global/components/FeatureTable/exportData";
import { ButtonAddNew } from "../../global/components/ButtonAction";
import StyleContent from "../../../components/Global/StyleContent";
import StyleGridHeader from "../../../components/Global/StyleContent/StyleGrid/StyleGridHeader";
import DetailGrid from "../../../components/Global/Grid/DetailGrid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { getVendor } from "../../../Api/Master/GetData";
import {
  AddAuthorityIdx,
  DeleteAuthorityIdx,
  EditAuthorityIdx,
  PrintAuthorityIdx
} from "../../../components/Global/Authority";

const Vendor = () => {

  const myAddAuthIdx = AddAuthorityIdx();
  const myEditAuthIdx = EditAuthorityIdx();
  const myDeleteAuthIdx = DeleteAuthorityIdx();
  const myPrintAuthIdx = PrintAuthorityIdx();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const fetchData = async () => {
    try {
      const response = await getVendor();
      setData(response);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setData([]);
      }
    }
  };
  
  useEffect(() => {
    fetchData();
    setIsLoading(false);
  }, []);

  const columns = [
    {
      key: 'key',
      title: 'No',
      dataIndex: 'key',
      width: 80,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'Vendor Code',
      dataIndex: 'VendorCode',
      sorter: (a, b) => a.VendorCode.localeCompare(b.VendorCode),
    },
    {
      title: 'Vendor Name',
      dataIndex: 'VendorName',
      sorter: (a, b) => a.VendorName.localeCompare(b.VendorName),
    },
    {
      title: 'Address',
      dataIndex: 'Address',
      sorter: (a, b) => a.Address.localeCompare(b.Address),
    },
    {
      title: 'Address 2',
      dataIndex: 'Address2',
      sorter: (a, b) => a.Address2.localeCompare(b.Address2),
    },
    {
      title: 'Country',
      dataIndex: 'Country',
      sorter: (a, b) => a.Country.localeCompare(b.Country),
    },
    {
      title: 'City',
      dataIndex: 'City',
      sorter: (a, b) => a.City.localeCompare(b.City),
    },
    {
      title: 'Postal Code',
      dataIndex: 'PostalCode',
      sorter: (a, b) => a.PostalCode.localeCompare(b.PostalCode),
    },
    {
      title: 'Phone Number',
      dataIndex: 'Phone1',
      sorter: (a, b) => a.Phone1.localeCompare(b.Phone1),
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      sorter: (a, b) => a.Email.localeCompare(b.Email),
    },
    {
      title: 'Fax',
      dataIndex: 'Fax',
      sorter: (a, b) => a.Fax.localeCompare(b.Fax),
    },
    {
      title: 'Contact Person',
      dataIndex: 'ContactPerson',
      sorter: (a, b) => a.ContactPerson.localeCompare(b.ContactPerson),
    },
    {
      title: 'TOP Code',
      dataIndex: 'TOPCode',
      sorter: (a, b) => a.TOPCode.localeCompare(b.TOPCode),
    },
    {
      title: 'Act No',
      dataIndex: 'ActNo',
      sorter: (a, b) => a.ActNo.localeCompare(b.ActNo),
    },
    {
      title: 'Act Name',
      dataIndex: 'ActName',
      sorter: (a, b) => a.ActName.localeCompare(b.ActName),
    },
    {
      title: 'Bank Name',
      dataIndex: 'BankName',
      sorter: (a, b) => a.BankName.localeCompare(b.BankName),
    },
    {
      title: 'Bank Branch Name',
      dataIndex: 'BankBranchName',
      sorter: (a, b) => a.BankBranchName.localeCompare(b.BankBranchName),
    },
    {
      title: 'NPWP',
      dataIndex: 'NPWP',
      sorter: (a, b) => a.NPWP.localeCompare(b.NPWP),
    },
    {
      key: 'IsSuspend',
      title: 'Active',
      dataIndex: 'IsSuspend',
      width: 120,
      render: (isSuspend) => (
        <Box display="flex" justifyContent="center" >
          {
            isSuspend ? (
              <Tooltip title="Inactive">
                <CancelIcon color="error" />
              </Tooltip>
            ) : (
              <Tooltip title="Active" >
                <CheckCircleIcon color="success" />
              </Tooltip >
            )}
        </Box>
      )
    },
  ];

  if (myEditAuthIdx || myDeleteAuthIdx) {
    columns.push({
      title: 'Action',
      fixed: 'right',
      width: 120,
      render: (_, record) => (
        <Box display="flex" justifyContent="center" >
          {myEditAuthIdx && (
            <EditVendor data={record} onEdit={fetchData} />
          )}

          {myDeleteAuthIdx && !record.IsSuspend && (
            <DeleteVendor data={record} onDelete={fetchData} />
          )}
        </Box>
      ),
    })
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some(
      (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      // console.log(selectedRows);
    },
  };

  const columnsExport = columns
    .filter((item) => item.key !== "Action" && item.key !== "key")
    .map((item) => {

      if (item.title === "Active") {
        return {
          title: item.title,
          key: item.dataIndex,
          render: (record) => (record.IsSuspend ? "Inactive" : "Active"),
        };
      }

      return {
        title: item.title,
        key: item.dataIndex,
      };
    });

  const processExportData = (data) =>
    data.map((item) => ({
      ...item,
      IsSuspend: item.IsSuspend ? "Inactive" : "Active",
    }));

  return (
    <StyleContent>
      <StyleGridHeader>
        <Header title="VENDOR" subtitle="All Vendor Data" />

        <Box display="flex" justifyContent="end" gap="8px">
          {myPrintAuthIdx && (
            <ExportData
              data={processExportData(selectedRows.length > 0 ? selectedRows : data)}
              columns={columnsExport}
              name="Vendor"
            />
          )}

          {myAddAuthIdx && (
            <ButtonAddNew
              link="form"
            />
          )}
        </Box>

      </StyleGridHeader>

      <SearchData
        filterValue={searchText}
        handleFilterChange={handleSearch}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filteredData}
        rowSelection={data.length === 0 ? null : rowSelection}
        expandable={data.length === 0 ? null : {
          expandedRowRender: (record) => (
            <DetailGrid>
              <Typography variant="h5" fontWeight={600}>Description : </Typography>
              <Typography>{record.Description}</Typography>
            </DetailGrid>
          ),
          rowExpandable: (record) => record.Description !== '',
        }}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: 'max-content' }}
      />

    </StyleContent>
  );
};

export default Vendor;
