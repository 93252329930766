import { Box, Button } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import InputModal from '../../../components/Global/InputModal'
import { Formik } from 'formik'
import * as yup from "yup";
import { getLocation } from '../../../Api/Master/GetData';

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LoadingButton } from '@mui/lab';
import StyleForm from '../../../components/Global/StyleContent/StyleForm';
import dayjs from 'dayjs';
import { Image } from 'antd';

const Filters = ({ dataFilter }) => {

    const formRef = useRef(null);

    const [loading, setLoading] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [dataLocation, setDataLocation] = useState([]);
    const [selectLocation, setSelectLocation] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const FacilityCode = selectLocation ? selectLocation.Location.FacilityCode : '';
    const LocationName = selectLocation ? selectLocation.Location.LocationName : '';
    const LocationCode = selectLocation ? selectLocation.Location.LocationCode : '';
    // console.log("Data", selectEquipment);

    const [StartDate, setStartDate] = useState(dayjs().startOf('year'));
    const [EndDate, setEndDate] = useState(dayjs().endOf('year'));

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getLocation('', 'false');
                setDataLocation(res);
            } catch (error) {
                console.log(error);
            }
        }

        if (openModal) {
            fetchData();
            setIsLoading(false);
        }

    }, [openModal]);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.setFieldValue("LocationName", LocationName);
        }
    }, [LocationName]);


    const handleSubmit = (values) => {
        try {
            setLoading(true);
            const payload = {
                TypeCode: LocationCode,
                FacilityCode: FacilityCode,
                StartDate: StartDate.format("YYYY-MM-DD"),
                EndDate: EndDate.format("YYYY-MM-DD"),
            }
            console.log(payload);
            dataFilter(payload);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = (resetForm) => {
        resetForm();
        setSelectLocation("");
        setStartDate(dayjs().startOf('year'));
        setEndDate(dayjs().endOf('year'));
        dataFilter(null);
    }

    return (
        <>
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                innerRef={formRef}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    resetForm,
                }) => (
                    <Box
                        sx={{
                            marginBottom: 4
                        }}
                    >
                        <StyleForm>
                            <InputModal
                                title="LOCATION"
                                label="Location Name"
                                name="LocationName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.LocationName}
                                error={!!touched.LocationName && !!errors.LocationName}
                                helperText={touched.LocationName && errors.LocationName}
                                dataSource={dataLocation}
                                columns={columnsLocation}
                                loading={isLoading}
                                propsSelecteRow={(values) => setSelectLocation(values)}
                                propsOpenModal={(values) => setOpenModal(values)}
                            />

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Start Date*"
                                    value={StartDate}
                                    onBlur={handleBlur}
                                    onChange={(date) => setStartDate(date)}
                                    name="StartDate"
                                    error={!!touched.StartDate && !!errors.StartDate}
                                    helperText={touched.StartDate && errors.StartDate}
                                    sx={{ gridColumn: "span 1" }}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="End Date*"
                                    value={EndDate}
                                    onBlur={handleBlur}
                                    onChange={(date) => setEndDate(date)}
                                    name="EndDate"
                                    error={!!touched.EndDate && !!errors.EndDate}
                                    helperText={touched.EndDate && errors.EndDate}
                                    sx={{ gridColumn: "span 1" }}
                                />
                            </LocalizationProvider>

                        </StyleForm>

                        <Box
                            sx={{
                                display: "flex",
                                marginTop: 1,
                                px: 3,
                                gap: 1,
                                justifyContent: "flex-end",
                                alignItems: "center"
                            }}
                        >
                            <Button
                                type="button"
                                color="error"
                                variant="contained"
                                onClick={() => handleCancel(resetForm)}
                            >
                                <span>Cancel</span>
                            </Button>
                            <LoadingButton
                                color="primary"
                                onClick={handleSubmit}
                                loading={loading}
                                variant="contained"
                            >
                                <span>Submit</span>
                            </LoadingButton>
                        </Box>


                    </Box>
                )}
            </Formik>
        </>
    )
}

export default Filters;

const checkoutSchema = yup.object().shape({
    LocationName: yup
        .string()
        .required("Please complete this Location Name."),
});

const initialValues = {
    LocationName: "",
};

const columnsLocation = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        width: 80,
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        title: 'Location Code',
        dataIndex: ['Location', 'LocationCode'],
        fixed: 'left',
        sorter: (a, b) => a.Location.LocationCode.localeCompare(b.Location.LocationCode),
    },
    {
        title: 'Location Name',
        dataIndex: ['Location', 'LocationName'],
        sorter: (a, b) => a.Location.LocationName.localeCompare(b.Location.LocationName),
    },
    {
        title: 'Facility Name',
        dataIndex: 'FacilityName',
        sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
        title: 'Building Name',
        dataIndex: 'BuildingName',
        sorter: (a, b) => a.BuildingName.localeCompare(b.BuildingName),
    },
    {
        title: 'Floor Level Name',
        dataIndex: 'FloorLevelName',
        sorter: (a, b) => a.FloorLevelName.localeCompare(b.FloorLevelName),
    },
    {
        title: 'Map Name',
        dataIndex: 'MapName',
        sorter: (a, b) => a.MapName.localeCompare(b.MapName),
    },
    {
        title: 'Icon Name',
        dataIndex: 'IconName',
        sorter: (a, b) => a.IconName.localeCompare(b.IconName),
    },
    {
        title: 'Icon Image',
        dataIndex: 'Icon Image',
        render: (value, record) =>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Image
                    style={{ maxWidth: 40, maxHeight: "100%", objectFit: "contain" }}
                    src={`${record.Location.url}`}
                />
            </div>
    },
]