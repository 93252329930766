import React, { useEffect, useState } from 'react'
import { Collapse, Table } from 'antd'
import { Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { tokens } from '../../../theme';
import SearchData from '../../../pages/global/components/FeatureTable/SearchData';

const Periods = ({ dataSource }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const items = dataSource.map((item, index) => ({
        key: index,
        label: <Typography
            variant="h5"
            color={colors.grey[900]}
        >
            {item.periode}
        </Typography>,
        children: <Transaction dataSource={item.transactions} />,
    }))

    return (
        <>
            <Typography
                variant="h4"
                color={colors.grey[900]}
                fontWeight={600}
                mb={2}
            >
                Periode
            </Typography>
            <Collapse items={items} />
        </>
    )
}

export default Periods;

// TRANSACTION
const Transaction = ({ dataSource }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [data, setData] = useState([]);

    const [showAllResources, setShowAllResources] = useState(false);
    const [showAllParts, setShowAllParts] = useState(false);
    const [showAllPartQty, setShowAllPartQty] = useState(false);
    const [showAllDocuments, setShowAllDocuments] = useState(false);
    const [showAllContacts, setShowAllContacts] = useState(false);

    useEffect(() => {
        const fetchData = () => {
            try {
                const data = dataSource.map((item, index) => ({ key: index + 1, ...item }));
                setData(data);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
        setIsLoading(false);
    }, [dataSource]);


    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = data.filter((item) =>
        Object.values(item).some(
            (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    const toggleShow = (key, type) => {
        if (type === 'resources') {
            setShowAllResources(prevState => ({
                ...prevState,
                [key]: !prevState[key] || false,
            }));
        } else if (type === 'parts') {
            setShowAllParts(prevState => ({
                ...prevState,
                [key]: !prevState[key] || false,
            }));

        } else if (type === 'partQty') {
            setShowAllPartQty(prevState => ({
                ...prevState,
                [key]: !prevState[key] || false,
            }));
        }
        else if (type === 'documnets') {
            setShowAllDocuments(prevState => ({
                ...prevState,
                [key]: !prevState[key] || false,
            }));
        } else if (type === 'contacts') {
            setShowAllContacts(prevState => ({
                ...prevState,
                [key]: !prevState[key] || false,
            }));
        }
    };

    const renderItems = (text, record, items, type) => {
        // Check if text is null or undefined
        if (!text) {
            return <p>No items available</p>; // or return null
        }

        const itemNames = text.split(',');
        const showAll = items[record.key];

        const visibleItems = showAll ? itemNames : itemNames.slice(0, 3);

        return (
            <div>
                {visibleItems.map((item, index) => (
                    <p key={index}>{`▪ ${item.trim()}`}</p>
                ))}
                {itemNames.length > 3 && (
                    <div>
                        {!showAll ? (
                            <Link onClick={() => toggleShow(record.key, type)}>show all..</Link>
                        ) : (
                            <Link onClick={() => toggleShow(record.key, type)}>hide..</Link>
                        )}
                    </div>
                )}
            </div>
        );
    };

    const renderResources = (text, record) => renderItems(text, record, showAllResources, 'showAllResources');
    const renderParts = (text, record) => renderItems(text, record, showAllParts, 'parts');
    const renderPartQty = (text, record) => renderItems(text, record, showAllPartQty, 'partQty');
    const renderDocuments = (text, record) => renderItems(text, record, showAllDocuments, 'showAllDocuments');
    const renderContacts = (text, record) => renderItems(text, record, showAllContacts, 'showAllContacts');


    const columns = [
        {
            title: 'Tran Number',
            dataIndex: 'tranNumber',
            sorter: (a, b) => a.tranNumber.localeCompare(b.tranNumber),
        },
        // {
        //     title: 'Asset Name',
        //     dataIndex: 'AssetName',
        //     sorter: (a, b) => a.BuildingName.localeCompare(b.BuildingName),
        // },
        // {
        //     title: 'Asset Owner',
        //     dataIndex: 'AssetOwner',
        //     sorter: (a, b) => a.BuildingName.localeCompare(b.BuildingName),
        // },
        {
            title: 'Resource',
            dataIndex: 'resource',
            sorter: (a, b) => a.resource.localeCompare(b.resource),
            render: renderResources
        },
        {
            title: 'Part Name',
            dataIndex: 'partName',
            sorter: (a, b) => a.partName.localeCompare(b.partName),
            render: renderParts
        },
        {
            title: 'Part QTY',
            dataIndex: 'partQTY',
            sorter: (a, b) => a.partQTY.localeCompare(b.partQTY),
            render: renderPartQty
        },
        {
            title: 'Document',
            dataIndex: 'document',
            sorter: (a, b) => a.document.localeCompare(b.document),
            render: renderDocuments
        },
        {
            title: 'Contact',
            dataIndex: 'contact',
            sorter: (a, b) => a.contact.localeCompare(b.contact),
            render: renderContacts
        },
    ];

    return (
        <>
            <SearchData
                filterValue={searchText}
                handleFilterChange={handleSearch}
            />
            <Table
                loading={isLoading}
                columns={columns}
                dataSource={filteredData}
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                scroll={{ x: 'max-content' }}
            />

        </>
    )
}