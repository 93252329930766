import React, { useState, useEffect, useCallback } from "react";
import Header from "../../../components/Header";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { StyleContent } from "../../global/StyleCSS";
import { Space, Table, } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import { getTranApproval } from "../Api/GetData";
import ProcessTranApproval from "./process";
import ViewTranApproval from "./view";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { getFacility } from "../../../Api/Master/GetData";

const TranApproval = () => {

  const { user } = useSelector((state) => state.auth);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [facilityCode, setFacilityCode] = useState("");

  const [searchText, setSearchText] = useState('');

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some((val) =>
      val && val.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  useEffect(() => {
    const fetchFacility = async () => {
      try {
        const res = await getFacility();
        const Codes = res.map(item => item.FacilityCode).join(",");
        setFacilityCode(Codes);
      } catch (error) {
        console.log(error);
      }
    };

    if (!user.FacilityAuthority.join(",")) {
      fetchFacility();
    } else {
      setFacilityCode(user.FacilityAuthority.join(","));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      if (user && facilityCode) {
        const response = await getTranApproval(facilityCode, user.UserName);
        setData(response);
      }
    } catch (error) {
      //console.log(error);
      if (error.response && error.response.status === 404) {
        setData([]);
      }
    }
    setIsLoading(false);
  }, [facilityCode, user]);


  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      key: 'key',
      title: 'No',
      dataIndex: 'key',
      fixed: 'left',
      width: 80,
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'Facility Name',
      dataIndex: 'FacilityName',
      sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
      title: 'Tran Name',
      dataIndex: 'TranName',
      sorter: (a, b) => a.TranName.localeCompare(b.TranName),
      render: (value) => <p style={{ fontWeight: 600 }}>{value}</p>
    },
    {
      title: 'Tran Date',
      dataIndex: 'TranDate',
      sorter: (a, b) => a.TranDate.localeCompare(b.TranDate),
      render: (text) => <p>{dayjs(text).format('YYYY-MM-DD')}</p>
    },
    {
      title: 'Tran Number',
      dataIndex: 'TranNumber',
      sorter: (a, b) => a.TranNumber.localeCompare(b.TranNumber),
    },
    {
      title: 'App Date',
      dataIndex: 'AppDate',
      sorter: (a, b) => a.AppDate.localeCompare(b.AppDate),
      render: (text) => <p>{dayjs(text).format('YYYY-MM-DD')}</p>
    },
    {
      title: 'App Status',
      dataIndex: 'AppStatus',
      sorter: (a, b) => a.AppStatus.localeCompare(b.AppStatus),
      render: (value) => <p>{value ? value : '-'}</p>
    },
    {
      title: 'App Level',
      dataIndex: 'AppLevel',
      sorter: (a, b) => a.AppLevel.localeCompare(b.AppLevel),
    },
    {
      title: 'Action',
      fixed: 'right',
      render: (_, record) => (
        <Space>
          {/* {!record.AppStatus ? (<ProcessTranApproval data={record} onEdit={fetchData} />) : ''} */}
          <ProcessTranApproval data={record} onEdit={fetchData} />
          <ViewTranApproval data={record} />
        </Space>
      ),
    },
  ]

  const isTableRes = useMediaQuery("(max-width:1280px)");

  return (
    <Box sx={StyleContent}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Header
          title="APPROVAL"
          subtitle="All Approval data"
        />
        {/* <Box display="flex" justifyContent="end" gap="8px">

          <ExportData
            data={selectedRows.length > 0 ? selectedRows : data}
            columns={columnsExport}
            name="TranApproval"
          />

          <ButtonAddNew
            link="/master/facility/form"
          />

        </Box> */}
      </Box>

      <SearchData
        filterValue={searchText}
        handleFilterChange={handleSearch}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filteredData}
        // expandable={data.length === 0 ? null : {
        //   expandedRowRender: (record) => (
        //     <DetailGrid>
        //       <Typography variant="h5" fontWeight={600}>Description : </Typography>
        //       <Typography>{record.Description}</Typography>
        //     </DetailGrid>
        //   ),
        //   rowExpandable: (record) => record.Description !== '',
        // }}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={isTableRes && { x: 'max-content' }}
      />


    </Box>
  );
};

export default TranApproval;
