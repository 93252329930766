import React, { useState } from 'react';
import { Form, Input, Popconfirm, Table, Typography, TimePicker, InputNumber } from 'antd';

import { CloseOutlined, DeleteOutlined, EditFilled, SaveFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { LoadingButton } from "@mui/lab"
import { Button } from '@mui/material';
import { getMPResourceTrasaction, getVendorEmployee } from '../../Api/GetData';
import dayjs from 'dayjs';
import InputDetailModal from '../../../../components/Global/InputDetailModal';
import { toRupiah } from '../../../../components/Global/Grid/DetailGrid';

// const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;


const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    onData,
    onDataResource,
    onEdit,
    onPMRE,
    ...restProps
}) => {

    useEffect(() => {
        if (onEdit || onPMRE) {
            setOpenResource(true);
        }
    }, [onEdit, onPMRE]);


    const [isLoading, setIsLoading] = useState(true);

    const [dataResource, setDataResource] = useState([]);
    const [selectResource, setSelectResource] = useState("");
    const [openResource, setOpenResource] = useState(null);

    useEffect(() => {
        const fetchResource = async () => {
            try {
                const VendorCode = onData.map(item => item.VendorCode);

                const res = await getVendorEmployee();
                const filter = res.filter(item => !VendorCode.includes(item.ResourceCode));
                setDataResource(filter);

                if (onEdit || onPMRE) {
                    const selected = res.filter(item => item.ResourceCode === record.VendorCode);
                    setSelectResource(selected[0]);
                }

            } catch (error) {
                console.log(error);
            }
        }

        if (openResource) {
            fetchResource();
            setOpenResource(false);
            setIsLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openResource]);

    useEffect(() => {
        if (selectResource) {
            onDataResource(selectResource);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectResource]);

    return (
        <td {...restProps}>
            {editing ? (
                <div>

                    {dataIndex === 'Description' ? (
                        <Form.Item
                            name={dataIndex}
                            style={{
                                margin: 0,
                            }}
                        >

                            <TextArea rows={4} placeholder={title} />
                        </Form.Item>

                    ) : dataIndex === 'Resource' && editing ? (

                        <InputDetailModal
                            title="RESOURCE"
                            label={title}
                            name={dataIndex}
                            dataSource={dataResource}
                            loading={isLoading}
                            columns={columnsResource}
                            onData={(values) => setSelectResource(values)}
                            onOpenModal={(values) => setOpenResource(values)}
                            onDetail={true}
                        />

                    ) : (

                        <Form.Item
                            name={dataIndex}
                            style={{
                                margin: 0,
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: `Please Input ${title}!`,
                                },
                            ]}
                        >
                            {dataIndex === 'Duration' && editing ? (
                                <TimePicker
                                    minuteStep={15}
                                    hourStep={1}
                                    // defaultOpenValue={dayjs('00:00', 'HH:mm')}
                                    style={{
                                        width: "100%",
                                    }}
                                    format={'HH:mm'}
                                    showNow={false}
                                />

                            ) : (
                                dataIndex === 'CostPerHour' && editing ? (
                                    <InputNumber
                                        prefix="Rp"
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                        style={{
                                            width: "100%",
                                        }}
                                    />
                                ) :
                                    < Input placeholder={title} />
                            )

                            }
                        </Form.Item>
                    )}

                </div>
            ) : (
                children
            )
            }
        </td >
    );
};


const FormMPRE = ({ onSaveData, FacilityCode, MPNumber, onEdit, onApproval, PMRE }) => {

    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);

    const [editingKey, setEditingKey] = useState('');
    const [loading, setLoading] = useState(false);
    const [isDisable, setIsDisable] = useState(true);

    const [dataResource, setDataResource] = useState(null);

    // console.log("Data", dataResource);

    useEffect(() => {
        if (form && dataResource) {
            form.setFieldsValue({
                Resource: dataResource.ResourceName
            })
        }
    }, [dataResource, form]);

    useEffect(() => {
        if (onEdit || onApproval) {
            const fetchData = async () => {
                try {
                    const resRE = await getMPResourceTrasaction(FacilityCode, MPNumber);
                    console.log("RE", resRE);
                    
                    const filter = resRE.map((row, index) => ({
                        ...row, key: index + 1
                    })).reverse()
                    setData(filter)
                    setCount(filter.length === 0 ? 0 : filter.map((item) => item.key)[0])
                    onSaveData(filter)
                } catch (error) {
                    setData([]);
                    setCount(0);
                    onSaveData([]);
                    console.log(error);
                }
            }
            fetchData()
        } else if (PMRE.length > 0) {
            const datas = PMRE.map((item) => ({
                ...item,
                VendorCode: item.VendorCode,
                Resource: item.Resource,
                Duration: '00-00-00',
                CostPerHour: 0,
                Description: '',
            }))
            setData(datas);
            setCount(datas.map((item) => item.DetailNo)[0]);
            onSaveData(datas);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [FacilityCode, MPNumber, onEdit, PMRE]);

    const isEditing = (record) => record.key === editingKey;

    const handleEdit = (record) => {

        const vendor = record.Resource ? record.Resource : [];
        const DurationNow = record.Duration ? dayjs(record.Duration, 'HH:mm:ss') : null;


        form.setFieldsValue({
            Description: '',
            ...record,
            Resource: vendor,
            Duration: DurationNow,
        });
        setEditingKey(record.key);

        // console.log("DataFormTran", data);
    };


    const handleDelete = (key) => {
        setIsDisable(false);
        const deletedRow = data.find((row) => row.key === key);
        const deletedNumber = deletedRow.key;
        const deletedkey = deletedRow.key;

        const newData = data.filter((row) => row.key !== key);

        const updatedData = newData.map((row) => {

            if (row.key > deletedNumber && row.key > deletedkey) {
                return { ...row, key: row.key - 1, DetailNo: row.DetailNo - 1 };
            }
            return row;
        });

        setCount(updatedData.length > 0 ? updatedData[0].key : 0);

        setData(updatedData);
        onSaveData(updatedData);

        // console.log("DataFormTran", updatedData);
    };


    const handleCancel = (record) => {
        if (!record.VendorCode) {
            const newData = data.filter((item) => item.key !== record.key);
            setData(newData);
        } else {
            setEditingKey('');
        }
        setEditingKey('');

        // console.log("DataFormTran", data);
    };


    const handleSave = async (record) => {
        setIsDisable(false);
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => record.key === item.key);

            const VendorCode = dataResource.ResourceCode;
            const DurationNow = row.Duration.format('HH:mm:ss');


            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                    VendorCode: VendorCode,
                    Duration: DurationNow,
                });
                setData(newData);
                setEditingKey('');
                console.log("DataFormTran", newData);
                onSaveData(newData)
            } else {
                newData.push({
                    ...row,
                    VendorCode: VendorCode,
                    Duration: DurationNow,
                });
                setData(newData);
                setEditingKey('');
                console.log("DataFormTran", newData);
                onSaveData(newData)
            }

            const editedRow = data.find((row) => row.key === record.key);
            const lastNumber = editedRow.key;

            setCount(lastNumber);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };



    const handleAdd = () => {

        const num = count + 1;

        if (editingKey) {
            enqueueSnackbar("Complete the input form !", { variant: "warning" });
            return; // Stop saving if duplicate found
        }

        const newData = {
            key: num,
            FacilityCode: FacilityCode,
            MPNumber: MPNumber,
            DetailNo: num,
            Resource: '',
            Duration: '',
            CostPerHour: '',
            Description: '',
        };
        setData([newData, ...data]);
        handleEdit(newData);

        // console.log("DataFormTran", data);
    };

    const handleSaveAllData = async () => {
        setLoading(true);
        setIsDisable(true);
        try {
            const newData = data.map(item => ({
                ...item,
                // VendorCode: vendor.find(ven => ven.ResourceName === item.Resource)?.ResourceCode
            }));
            onSaveData(newData);
            console.log("PostData", data);
            enqueueSnackbar("Success add form table data!!", { variant: "success" });
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const handleCancelAllData = () => {
        setData([]);
        setCount(0);
        onSaveData([]);
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            sorter: (a, b) => a.key - b.key,
            width: 80,
            fixed: 'left',
        },
        {
            title: 'Resource',
            dataIndex: 'Resource',
            editable: true,
        },
        {
            title: 'Duration',
            dataIndex: 'Duration',
            editable: true,
        },
        {
            title: 'Cost Per Hours',
            dataIndex: 'CostPerHour',
            editable: true,
            render: (value) => <p>{toRupiah(value)}</p>
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            editable: true,
        },
    ];

    if (!onApproval) {
        columns.push({
            title: 'Actions',
            dataIndex: 'actions',
            fixed: 'right',
            width: 100,
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                        <Typography.Link onClick={() => handleSave(record)} style={{ fontSize: '18px' }}>
                            <SaveFilled />
                        </Typography.Link>

                        <Typography.Link onClick={() => handleCancel(record)} style={{ fontSize: '18px' }}>
                            <CloseOutlined />
                        </Typography.Link>
                    </span>
                ) : (
                    <span style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                        <Typography.Link onClick={() => handleEdit(record)} style={{ fontSize: '18px' }}>
                            <EditFilled />
                        </Typography.Link>
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                            <Link>
                                <DeleteOutlined style={{ fontSize: '18px' }} />
                            </Link>
                        </Popconfirm>
                    </span>
                );
            },
        })
    }


    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            onCell: (record) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                onData: data,
                onDataResource: (values) => setDataResource(values),
                onEdit: onEdit,
                onPMRE: PMRE
            }),
            ...col,
        };
    });

    return (
        <Form form={form} component={false}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "0 10px 10px"
                }}
            >
                <Title level={3} style={{ margin: "0" }}>
                    Resource
                </Title>
                {!onApproval && (
                    <Button
                        onClick={handleAdd}
                        color="primary"
                        variant="contained"
                        disabled={!!editingKey || !FacilityCode || !MPNumber}
                    >
                        + Add Data
                    </Button>
                )}
            </div>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                // bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                    onChange: handleCancel,
                    pageSize: 5,
                }}
                scroll={{
                    x: 1000,
                }}
            />

            {!onApproval && (
                <div
                    style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "flex-end",
                        margin: "10px 10px 0"
                    }}
                >
                    <Popconfirm title="Sure to clear all data?" onConfirm={handleCancelAllData}
                    >
                        <Button
                            type="button"
                            color="error"
                            variant="contained"
                            disabled={!!editingKey || !!isDisable}
                        >
                            <span>Cancel</span>
                        </Button>
                    </Popconfirm>
                    <LoadingButton
                        color="primary"
                        onClick={handleSaveAllData}
                        loading={loading}
                        variant="contained"
                        disabled={!!editingKey || !!isDisable}
                    >
                        <span>Save</span>
                    </LoadingButton>

                </div>

            )}
        </Form>
    );
};
export default FormMPRE;

const columnsResource = [
    {
        title: 'No',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: 'Resource Group',
        dataIndex: 'ResourceGroup',
        key: 'ResourceGroup',
    },
    {
        title: 'Resource Name',
        dataIndex: 'ResourceName',
        key: 'ResourceName',
    },
]