export const columnsFacility = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        key: 'FacilityCode',
        title: 'Facility Code',
        dataIndex: 'FacilityCode',
        fixed: 'left',
        sorter: (a, b) => a.FacilityCode.localeCompare(b.FacilityCode),
    },
    {
        key: 'FacilityName',
        title: 'Facility Name',
        dataIndex: 'FacilityName',
        sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
        key: 'Address',
        title: 'Address',
        dataIndex: 'Address',
        sorter: (a, b) => a.Address.localeCompare(b.Address),
    },
    {
        key: 'City',
        title: 'City',
        dataIndex: 'City',
        sorter: (a, b) => a.City.localeCompare(b.City),
    },
    {
        key: 'Country',
        title: 'Country',
        dataIndex: 'Country',
        sorter: (a, b) => a.Country.localeCompare(b.Country),
    },
    {
        key: 'Phone1',
        title: 'Phone Number 1',
        dataIndex: 'Phone1',
        sorter: (a, b) => a.Phone1.localeCompare(b.Phone1),
    },
    {
        key: 'Phone2',
        title: 'Phone Number 2',
        dataIndex: 'Phone2',
        sorter: (a, b) => a.Phone2.localeCompare(b.Phone2),
    },
    {
        key: 'Fax',
        title: 'Fax',
        dataIndex: 'Fax',
        sorter: (a, b) => a.Fax.localeCompare(b.Fax),
    },
    {
        key: 'ContactPerson',
        title: 'Contact Person',
        dataIndex: 'ContactPerson',
        sorter: (a, b) => a.ContactPerson.localeCompare(b.ContactPerson),
    },
];



export const columnsRequestBy = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        title: 'Employee Name',
        dataIndex: ['Employee', 'EmpName'],
        sorter: (a, b) => a.Employee.EmpName.localeCompare(b.Employee.EmpName),
    },
    {
        title: 'Address',
        dataIndex: ['Employee', 'Address'],
        sorter: (a, b) => a.Employee.Address.localeCompare(b.Employee.Address),
    },
    {
        title: 'Phone Number 1',
        dataIndex: ['Employee', 'Phone1'],
        sorter: (a, b) => a.Employee.Phone1.localeCompare(b.Employee.Phone1),
    },
    {
        title: 'Phone Number 2',
        dataIndex: ['Employee', 'Phone2'],
        sorter: (a, b) => a.Employee.Phone2.localeCompare(b.Employee.Phone2),
    },
    {
        title: 'NIK',
        dataIndex: ['Employee', 'NIK'],
        sorter: (a, b) => a.Employee.NIK.localeCompare(b.Employee.NIK),
    },
    {
        title: 'Division Name',
        dataIndex: 'DivisionName',
        sorter: (a, b) => a.DivisionName.localeCompare(b.DivisionName),
    },
    {
        title: 'Department Name',
        dataIndex: 'DepName',
        sorter: (a, b) => a.DepName.localeCompare(b.DepName),
    },
    {
        title: 'Section Name',
        dataIndex: 'SectionName',
        sorter: (a, b) => a.SectionName.localeCompare(b.SectionName),
    },
    {
        title: 'IsTechnician',
        dataIndex: ['Employee', 'IsTechnician'],
        sorter: (a, b) => a.Employee.IsTechnician.localeCompare(b.Employee.IsTechnician),
        render: (isTechnician) => (
            <span>
                {isTechnician ? 'Technician' : 'Not a technician'}
            </span>
        ),
    }
]

export const columnsLocation = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        title: 'Location Code',
        dataIndex: ['Location', 'LocationCode'],
        fixed: 'left',
        sorter: (a, b) => a.Location.LocationCode.localeCompare(b.Location.LocationCode),
    },
    {
        title: 'Location Name',
        dataIndex: ['Location', 'LocationName'],
        sorter: (a, b) => a.Location.LocationName.localeCompare(b.Location.LocationName),
    },
    {
        title: 'Facility Name',
        dataIndex: 'FacilityName',
        sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
        title: 'Building Name',
        dataIndex: 'BuildingName',
        sorter: (a, b) => a.BuildingName.localeCompare(b.BuildingName),
    },
    {
        title: 'Floor Level Name',
        dataIndex: 'FloorLevelName',
        sorter: (a, b) => a.FloorLevelName.localeCompare(b.FloorLevelName),
    },
    {
        title: 'Map Name',
        dataIndex: 'MapName',
        sorter: (a, b) => a.MapName.localeCompare(b.MapName),
    },
    {
        title: 'IconId',
        dataIndex: ['Location', 'IconId'],
        sorter: (a, b) => a.Location.IconId.localeCompare(b.Location.IconId),
    },
    {
        title: 'Icon Location Y',
        dataIndex: ['Location', 'IconLocationY'],
        sorter: (a, b) => a.Location.IconLocationY.localeCompare(b.Location.IconLocationY),
    },
    {
        title: 'Icon Location X',
        dataIndex: ['Location', 'IconLocationX'],
        sorter: (a, b) => a.Location.IconLocationX.localeCompare(b.Location.IconLocationX),
    }
];

export const columnsEquipment = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        title: 'Equipment Code',
        dataIndex: ['Equipment', 'EquipmentCode'],
        fixed: 'left',
        sorter: (a, b) => a.Equipment.EquipmentCode.localeCompare(b.Equipment.EquipmentCode),
    },
    {
        title: 'Equipment Name',
        dataIndex: ['Equipment', 'EquipmentName'],
        sorter: (a, b) => a.Equipment.EquipmentName.localeCompare(b.Equipment.EquipmentName),
    },
    {
        title: 'Facility Name',
        dataIndex: 'FacilityName',
        sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
        title: 'Model Name',
        dataIndex: 'EquipmentModelName',
        sorter: (a, b) => a.EquipmentModelName.localeCompare(b.EquipmentModelName),
    },
    {
        title: 'Location Name',
        dataIndex: 'LocationName',
        sorter: (a, b) => a.LocationName.localeCompare(b.LocationName),
    },
    {
        title: 'SN',
        dataIndex: ['Equipment', 'SN'],
        sorter: (a, b) => a.Equipment.SN.localeCompare(b.Equipment.SN),
    },
    {
        title: 'Asset No',
        dataIndex: ['Equipment', 'AssetNo'],
        sorter: (a, b) => a.Equipment.AssetNo.localeCompare(b.Equipment.AssetNo),
    },
    {
        title: 'Install Date',
        dataIndex: ['Equipment', 'InstallDate'],
        sorter: (a, b) => a.Equipment.InstallDate.localeCompare(b.Equipment.InstallDate),
    },
    {
        title: 'Purchase Date',
        dataIndex: ['Equipment', 'PurchaseDate'],
        sorter: (a, b) => a.Equipment.PurchaseDate.localeCompare(b.Equipment.PurchaseDate),
    },
    {
        title: 'Warranty Exp Date',
        dataIndex: ['Equipment', 'WarrantyExpDate'],
        sorter: (a, b) => a.Equipment.WarrantyExpDate.localeCompare(b.Equipment.WarrantyExpDate),
    },
    {
        title: 'Meter Reading',
        dataIndex: ['Equipment', 'MeterReading'],
        sorter: (a, b) => a.Equipment.MeterReading.localeCompare(b.Equipment.MeterReading),
    },
    {
        title: 'Equipment Owner',
        dataIndex: 'EmployeeName',
        sorter: (a, b) => a.EmployeeName.localeCompare(b.EmployeeName),
    },
    {
        title: 'Map Name',
        dataIndex: 'MapName',
        sorter: (a, b) => a.MapName.localeCompare(b.MapName),
    }
];


export const columnsVehicle = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        title: 'Vehicle Code',
        dataIndex: 'VehicleCode',
        fixed: 'left',
        sorter: (a, b) => a.VehicleCode.localeCompare(b.VehicleCode),
    },
    {
        title: 'Vehicle Name',
        dataIndex: 'VehicleName',
        sorter: (a, b) => a.VehicleName.localeCompare(b.VehicleName),
    },
    {
        title: 'Model Type Code',
        dataIndex: 'ModelTypeCode',
        sorter: (a, b) => a.ModelTypeCode.localeCompare(b.ModelTypeCode),
    },
    {
        title: 'No Pol',
        dataIndex: 'NoPol',
        sorter: (a, b) => a.NoPol.localeCompare(b.NoPol),
    },
    {
        title: 'Asset No',
        dataIndex: 'AssetNo',
        sorter: (a, b) => a.AssetNo.localeCompare(b.AssetNo),
    },
    {
        title: 'Purchase Date',
        dataIndex: 'PurchaseDate',
        sorter: (a, b) => a.PurchaseDate.localeCompare(b.PurchaseDate),
    },
    {
        title: 'Warranty Exp Date',
        dataIndex: 'WarrantyExpDate',
        sorter: (a, b) => a.WarrantyExpDate.localeCompare(b.WarrantyExpDate),
    },
    {
        title: 'Meter Reading',
        dataIndex: 'MeterReading',
        sorter: (a, b) => a.MeterReading - b.MeterReading,
    },
    {
        title: 'Vehicle Owner',
        dataIndex: 'VehicleOwner',
        sorter: (a, b) => a.VehicleOwner.localeCompare(b.VehicleOwner),
    }
];