import { Box, Button, TextField } from '@mui/material'
import { Modal, Table } from 'antd'
import React, { useState, useEffect } from 'react'
import Header from '../Header';
import SearchData from '../../pages/global/components/FeatureTable/SearchData';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

const InputModal = ({
    title,
    label,
    name,
    onBlur,
    onChange,
    value,
    error,
    helperText,
    dataSource,
    columns,
    loading,
    propsSelecteRow,
    propsOpenModal,
    LinktoCreate
}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {
        const fetchData = () => {
            try {
                const response = dataSource;
                setData(response);
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        }

        fetchData();

    }, [dataSource]);


    const showModal = () => {
        setIsModalOpen(true);
        if (propsOpenModal) {
            propsOpenModal(true);
        }
    };

    const handleClose = () => {
        setIsModalOpen(false);
        propsOpenModal(false);
    };

    const handleSearch = (e) => {
        setSearchText(e.target.value);
        setIsSearching(true); // Set loading saat pencarian dimulai
    };

    // Filter data saat searchText berubah
    const filteredData = data.filter((item) =>
        Object.values(item).some(
            (val) => val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    // Hentikan loading setelah data difilter
    useEffect(() => {
        if (isSearching) {
            const timeoutId = setTimeout(() => {
                setIsSearching(false);
            }, 500); // Misalnya 500ms untuk simulasi delay pencarian
            return () => clearTimeout(timeoutId);
        }
    }, [filteredData, isSearching]);

    const rowSelection = {
        type: "radio",
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            propsSelecteRow(selectedRows[0]);
            setIsModalOpen(false);
        },
    };

    const handleRowClick = (record, rowIndex) => {
        setSelectedRowKeys([record.key]);
        propsSelecteRow(record);
        setIsModalOpen(false);
    };

    return (
        <>
            <TextField
                fullWidth
                variant="outlined"
                type="text"
                label={label}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                name={name}
                error={error}
                helperText={helperText}
                sx={{ gridColumn: "span 2" }}
                onClick={showModal}
                InputProps={{
                    readOnly: true,
                }}
            />

            <Modal
                centered
                title={
                    <Box display="flex" justifyContent="space-between">
                        <Header title={title} subtitle="" />
                        <Link to={LinktoCreate} target="_blank" style={{ textDecoration: 'none' }}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleClose}
                            >
                                <AddIcon sx={{ mr: 1 }} /> Add New
                            </Button>
                        </Link>
                    </Box>
                }
                open={isModalOpen}
                closable={false}
                footer={
                    <Button
                        type="button"
                        color="error"
                        variant="contained"
                        onClick={handleClose}
                    >
                        <span>Cancel</span>
                    </Button>
                }
                style={{
                    maxWidth: "90%",
                    minWidth: "80%",
                }}
            // bodyStyle={{
            //     maxHeight: "65vh",
            //     overflow: "auto",
            // }}
            >
                <>
                    <div>
                        <SearchData
                            filterValue={searchText}
                            handleFilterChange={handleSearch}
                        />
                    </div>
                    <Table
                        loading={isLoading}
                        columns={columns}
                        dataSource={filteredData}
                        rowSelection={rowSelection}
                        onRow={(record, rowIndex) => ({
                            onClick: () => {
                                handleRowClick(record, rowIndex);
                            },
                        })}
                        // scroll={columns.length > 6 && { x: 2000 }}
                        scroll={{ x: 'max-content' }}
                    // locale={{
                    //     emptyText: (
                    //         !dataSource.length > 0 && (
                    //             <Box sx={{ textAlign: 'center', padding: '16px' }}>
                    //                 <p style={{ color: "#555555" }}>
                    //                     No data available.
                    //                 </p>
                    //                 <Link
                    //                     to={LinktoCreate}
                    //                     target="_blank"
                    //                     onClick={handleClose}
                    //                     style={{ marginTop: '12px', display: 'inline-block' }}
                    //                 >
                    //                     Click to create in the {title} data.
                    //                 </Link>
                    //             </Box>
                    //         )
                    //     ),
                    // }}
                    />

                </>
            </Modal>
        </>
    );
}

export default InputModal;
