import { Table } from 'antd'
import React, { useEffect, useState } from 'react'
import StyleContent from '../../../components/Global/StyleContent';
import { Box, Typography } from '@mui/material';
import Header from '../../../components/Header';
import SearchData from '../../global/components/FeatureTable/SearchData';
import Transaction from './Transaction';

const Asset = ({ dataSource }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = dataSource.map((row, index) => ({ ...row, key: index + 1 }))
        setData(res);
      } catch (error) {
        console.log(error.response.data);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [dataSource]);

  const columns = [
    {
      key: 'AssetName',
      title: 'Asset Name',
      dataIndex: 'AssetName',
    },
    {
      key: 'AssetOwner',
      title: 'Asset Owner',
      dataIndex: 'AssetOwner',
    },
  ];

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some((val) =>
      val && val.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  return (
    <StyleContent>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h3" color="#000000">
          Assets
        </Typography>

        <SearchData
          filterValue={searchText}
          handleFilterChange={handleSearch}
        />
      </Box>

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filteredData}
        expandable={{
          expandedRowRender,
        }}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: 'max-content' }}
      />

    </StyleContent>
  )
}

export default Asset;

const expandedRowRender = (record) => {
  return <Transaction dataSource={record.Transactions} />;
};