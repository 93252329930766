import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box } from "@mui/material";
import { Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import StyleContent from "../../../components/Global/StyleContent";
import { getIndividualAssetDetails } from "../../../Api/Report/GetData";
import Asset from "./Asset";

const IndividualAssetDetails = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getIndividualAssetDetails();
                setData(res);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
        setIsLoading(false);
    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            width: 100,
            fixed: 'left',
            sorter: (a, b) => a.key - b.key,
        },
        {
            title: 'FacilityName',
            dataIndex: 'FacilityName',
            sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
        },
        {
            title: 'TranType',
            dataIndex: 'TranType',
            sorter: (a, b) => a.TranType.localeCompare(b.TranType),
        },
    ];

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = data.filter((item) =>
        Object.values(item).some((val) =>
            val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    return (
        <StyleContent>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Header
                    title="ASSETS DETAILS"
                    subtitle="All Individual Asset Details"
                />
            </Box>

            <SearchData
                filterValue={searchText}
                handleFilterChange={handleSearch}
            />

            <Table
                loading={isLoading}
                columns={columns}
                dataSource={filteredData}
                expandable={{
                    expandedRowRender,
                }}
                pagination={{
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                scroll={{ x: 'max-content' }}
            />

        </StyleContent>
    );
};

export default IndividualAssetDetails;

const expandedRowRender = (record) => {
    return <Asset dataSource={record.Assets} />;
};