import { Box, Button } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import InputModal from '../../../components/Global/InputModal'
import { Formik } from 'formik'
import * as yup from "yup";
import { getEquipment } from '../../../Api/Master/GetData';

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LoadingButton } from '@mui/lab';
import StyleForm from '../../../components/Global/StyleContent/StyleForm';
import dayjs from 'dayjs';

const Filters = ({ dataFilter }) => {

    const formRef = useRef(null);

    const [loading, setLoading] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [dataEquipment, setDataEquipment] = useState([]);
    const [selectEquipment, setSelectEquipment] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const FacilityCode = selectEquipment ? selectEquipment.Equipment.FacilityCode : '';
    const EquipmentName = selectEquipment ? selectEquipment.Equipment.EquipmentName : '';
    const EquipmentCode = selectEquipment ? selectEquipment.Equipment.EquipmentCode : '';
    // console.log("Data", selectEquipment);

    const [StartDate, setStartDate] = useState(dayjs().startOf('year'));
    const [EndDate, setEndDate] = useState(dayjs().endOf('year'));

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getEquipment('', 'false');
                setDataEquipment(res);
            } catch (error) {
                console.log(error);
            }
        }

        if (openModal) {
            fetchData();
            setIsLoading(false);
        }

    }, [openModal]);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.setFieldValue("EquipmentName", EquipmentName);
        }
    }, [EquipmentName]);


    const handleSubmit = (values) => {
        try {
            setLoading(true);
            const payload = {
                TypeCode: EquipmentCode,
                FacilityCode: FacilityCode,
                StartDate: StartDate.format("YYYY-MM-DD"),
                EndDate: EndDate.format("YYYY-MM-DD"),
            }
            console.log(payload);
            dataFilter(payload);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = (resetForm) => {
        resetForm();
        setSelectEquipment("");
        setStartDate(dayjs().startOf('year'));
        setEndDate(dayjs().endOf('year'));
        dataFilter(null);
    }

    return (
        <>
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                innerRef={formRef}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    resetForm,
                }) => (
                    <Box
                        sx={{
                            marginBottom: 4
                        }}
                    >
                        <StyleForm>
                            <InputModal
                                title="EQUIPMENT"
                                label="Equipment Name"
                                name="EquipmentName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.EquipmentName}
                                error={!!touched.EquipmentName && !!errors.EquipmentName}
                                helperText={touched.EquipmentName && errors.EquipmentName}
                                dataSource={dataEquipment}
                                columns={columnsEquipment}
                                loading={isLoading}
                                propsSelecteRow={(values) => setSelectEquipment(values)}
                                propsOpenModal={(values) => setOpenModal(values)}
                            />

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Start Date*"
                                    value={StartDate}
                                    onBlur={handleBlur}
                                    onChange={(date) => setStartDate(date)}
                                    name="StartDate"
                                    error={!!touched.StartDate && !!errors.StartDate}
                                    helperText={touched.StartDate && errors.StartDate}
                                    sx={{ gridColumn: "span 1" }}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="End Date*"
                                    value={EndDate}
                                    onBlur={handleBlur}
                                    onChange={(date) => setEndDate(date)}
                                    name="EndDate"
                                    error={!!touched.EndDate && !!errors.EndDate}
                                    helperText={touched.EndDate && errors.EndDate}
                                    sx={{ gridColumn: "span 1" }}
                                />
                            </LocalizationProvider>

                        </StyleForm>

                        <Box
                            sx={{
                                display: "flex",
                                marginTop: 1,
                                px: 3,
                                gap: 1,
                                justifyContent: "flex-end",
                                alignItems: "center"
                            }}
                        >
                            <Button
                                type="button"
                                color="error"
                                variant="contained"
                                onClick={() => handleCancel(resetForm)}
                            >
                                <span>Cancel</span>
                            </Button>
                            <LoadingButton
                                color="primary"
                                onClick={handleSubmit}
                                loading={loading}
                                variant="contained"
                            >
                                <span>Submit</span>
                            </LoadingButton>
                        </Box>


                    </Box>
                )}
            </Formik>
        </>
    )
}

export default Filters;

const checkoutSchema = yup.object().shape({
    EquipmentName: yup
        .string()
        .required("Please complete this Equipment Name."),
});

const initialValues = {
    EquipmentName: "",
};

const columnsEquipment = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        width: 80,
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        title: 'Equipment Code',
        dataIndex: ['Equipment', 'EquipmentCode'],
        fixed: 'left',
        sorter: (a, b) => a.Equipment.EquipmentCode.localeCompare(b.Equipment.EquipmentCode),
    },
    {
        title: 'Equipment Name',
        dataIndex: ['Equipment', 'EquipmentName'],
        sorter: (a, b) => a.Equipment.EquipmentName.localeCompare(b.Equipment.EquipmentName),
    },
    {
        title: 'Facility Name',
        dataIndex: 'FacilityName',
        sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
        title: 'Building Name',
        dataIndex: 'BuildingName',
        sorter: (a, b) => a.BuildingName.localeCompare(b.BuildingName),
    },
    {
        title: 'Floor Level Name',
        dataIndex: 'FloorLevelName',
        sorter: (a, b) => a.FloorLevelName.localeCompare(b.FloorLevelName),
    },
    {
        title: 'Model Name',
        dataIndex: 'EquipmentModelName',
        sorter: (a, b) => a.EquipmentModelName.localeCompare(b.EquipmentModelName),
    },
    {
        title: 'Location Name',
        dataIndex: 'LocationName',
        sorter: (a, b) => a.LocationName.localeCompare(b.LocationName),
    },
    {
        title: 'SN',
        dataIndex: ['Equipment', 'SN'],
        sorter: (a, b) => a.Equipment.SN.localeCompare(b.Equipment.SN),
    },
    {
        title: 'Asset No',
        dataIndex: ['Equipment', 'AssetNo'],
        sorter: (a, b) => a.Equipment.AssetNo.localeCompare(b.Equipment.AssetNo),
    },
    {
        title: 'Install Date',
        dataIndex: ['Equipment', 'InstallDate'],
        sorter: (a, b) => a.Equipment.InstallDate.localeCompare(b.Equipment.InstallDate),
    },
    {
        title: 'Purchase Date',
        dataIndex: ['Equipment', 'PurchaseDate'],
        sorter: (a, b) => a.Equipment.PurchaseDate.localeCompare(b.Equipment.PurchaseDate),
    },
    {
        title: 'Warranty Exp Date',
        dataIndex: ['Equipment', 'WarrantyExpDate'],
        sorter: (a, b) => a.Equipment.WarrantyExpDate.localeCompare(b.Equipment.WarrantyExpDate),
    },
    {
        title: 'Meter Reading',
        dataIndex: ['Equipment', 'MeterReading'],
        sorter: (a, b) => a.Equipment.MeterReading.localeCompare(b.Equipment.MeterReading),
    },
    {
        title: 'Equipment Owner',
        dataIndex: 'EmployeeName',
        sorter: (a, b) => a.EmployeeName.localeCompare(b.EmployeeName),
    },
    {
        title: 'Map Name',
        dataIndex: 'MapName',
        sorter: (a, b) => a.MapName.localeCompare(b.MapName),
    }
]