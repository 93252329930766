import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box } from "@mui/material";
import { Table } from "antd";
import StyleContent from "../../../components/Global/StyleContent";
import { getEquipmentHistory } from "../../../Api/Report/GetData";
import Filters from "./Filters";
import Periods from "../../../components/Global/Report_History/Periods";

const EquipmentHistory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await getEquipmentHistory(
          dataFilter?.TypeCode,
          dataFilter?.FacilityCode,
          dataFilter?.StartDate,
          dataFilter?.EndDate
        );
        setData(response);

        // Atur baris pertama agar terbuka secara otomatis
        if (response?.length > 0) {
          setExpandedRowKeys([response[0].key]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (dataFilter) {
      fetchData();
    } else {
      setData([]);
    }

  }, [dataFilter]);

  const columns = [
    {
      title: "No",
      dataIndex: "key",
      width: 100,
      fixed: "left",
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: "Equipment Name",
      dataIndex: "equipmentName",
      sorter: (a, b) => a.equipmentName.localeCompare(b.equipmentName),
    },
    {
      title: "Equipment Model Name",
      dataIndex: "modelName",
      sorter: (a, b) => a.modelName.localeCompare(b.modelName),
    },
    {
      title: "Equipment Owner",
      dataIndex: "empName",
      sorter: (a, b) => a.empName.localeCompare(b.empName),
    },
    {
      title: "Facility Name",
      dataIndex: "facilityName",
      sorter: (a, b) => a.facilityName.localeCompare(b.facilityName),
    },
    {
      title: "Building Name",
      dataIndex: "buildingName",
      sorter: (a, b) => a.buildingName.localeCompare(b.buildingName),
    },
    {
      title: "Floor Level Name",
      dataIndex: "floorLevelName",
      sorter: (a, b) => a.floorLevelName.localeCompare(b.floorLevelName),
    },
    {
      title: "Map Name",
      dataIndex: "mapName",
      sorter: (a, b) => a.mapName.localeCompare(b.mapName),
    },
  ];

  return (
    <StyleContent>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Header
          title="EQUIPMENT HISTORY"
          subtitle="All Equipment History"
        />
      </Box>

      <Filters
        dataFilter={(values) => setDataFilter(values)}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        expandable={{
          expandedRowRender,
          expandedRowKeys,
          onExpand: (expanded, record) => {
            setExpandedRowKeys(expanded ? [record.key] : []);
          },
        }}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: "max-content" }}
      />
    </StyleContent>
  );
};

export default EquipmentHistory;

const expandedRowRender = (record) => {
  return <Periods dataSource={record.periods} />;
};
