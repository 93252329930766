import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box } from "@mui/material";
import { Table } from "antd";
import StyleContent from "../../../components/Global/StyleContent";
import { getVehicleHistory } from "../../../Api/Report/GetData";
import Filters from "./Filters";
import Periods from "../../../components/Global/Report_History/Periods";

const VehicleHistory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await getVehicleHistory(
          dataFilter?.TypeCode,
          dataFilter?.FacilityCode,
          dataFilter?.StartDate,
          dataFilter?.EndDate
        );
        setData(response);

        // Atur baris pertama agar terbuka secara otomatis
        if (response?.length > 0) {
          setExpandedRowKeys([response[0].key]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (dataFilter) {
      fetchData();
    } else {
      setData([]);
    }

  }, [dataFilter]);

  const columns = [
    {
      title: 'No',
      dataIndex: 'key',
      width: 100,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'Vehicle Name',
      dataIndex: 'vehicleName',
      sorter: (a, b) => a.vehicleName.localeCompare(b.vehicleName),
    },
    {
      title: 'Number Police',
      dataIndex: 'noPol',
      sorter: (a, b) => a.noPol.localeCompare(b.noPol),
    },
    {
      title: 'Meter Reading',
      dataIndex: 'meterReading',
      sorter: (a, b) => a.meterReading.localeCompare(b.meterReading),
    },
    {
      title: 'Vehicle Owner',
      dataIndex: 'empName',
      sorter: (a, b) => a.empName.localeCompare(b.empName),
    },
    {
      title: 'Facility Name',
      dataIndex: 'facilityName',
      sorter: (a, b) => a.facilityName.localeCompare(b.facilityName),
    },
  ];

  return (
    <StyleContent>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Header
          title="VEHICLE HISTORY"
          subtitle="All Vehicle History"
        />
      </Box>

      <Filters
        dataFilter={(values) => setDataFilter(values)}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        expandable={{
          expandedRowRender,
          expandedRowKeys,
          onExpand: (expanded, record) => {
            setExpandedRowKeys(expanded ? [record.key] : []);
          },
        }}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: "max-content" }}
      />
    </StyleContent>
  );
};

export default VehicleHistory;

const expandedRowRender = (record) => {
  return <Periods dataSource={record.periods} />;
};
