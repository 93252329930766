import { Box, Button } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import InputModal from '../../../components/Global/InputModal'
import { Formik } from 'formik'
import * as yup from "yup";
import { getVehicle } from '../../../Api/Master/GetData';

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LoadingButton } from '@mui/lab';
import StyleForm from '../../../components/Global/StyleContent/StyleForm';
import dayjs from 'dayjs';

const Filters = ({ dataFilter }) => {

    const formRef = useRef(null);

    const [loading, setLoading] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [dataVehicle, setDataVehicle] = useState([]);
    const [selectVehicle, setSelectVehicle] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const FacilityCode = selectVehicle ? selectVehicle.FacilityCode : '';
    const VehicleName = selectVehicle ? selectVehicle.VehicleName : '';
    const VehicleCode = selectVehicle ? selectVehicle.VehicleCode : '';
    // console.log("Data", selectEquipment);

    const [StartDate, setStartDate] = useState(dayjs().startOf('year'));
    const [EndDate, setEndDate] = useState(dayjs().endOf('year'));

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getVehicle('', 'false');
                setDataVehicle(res);
            } catch (error) {
                console.log(error);
            }
        }

        if (openModal) {
            fetchData();
            setIsLoading(false);
        }

    }, [openModal]);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.setFieldValue("VehicleName", VehicleName);
        }
    }, [VehicleName]);


    const handleSubmit = (values) => {
        try {
            setLoading(true);
            const payload = {
                TypeCode: VehicleCode,
                FacilityCode: FacilityCode,
                StartDate: StartDate.format("YYYY-MM-DD"),
                EndDate: EndDate.format("YYYY-MM-DD"),
            }
            console.log(payload);
            dataFilter(payload);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = (resetForm) => {
        resetForm();
        setSelectVehicle("");
        setStartDate(dayjs().startOf('year'));
        setEndDate(dayjs().endOf('year'));
        dataFilter(null);
    }

    return (
        <>
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                innerRef={formRef}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    resetForm,
                }) => (
                    <Box
                        sx={{
                            marginBottom: 4
                        }}
                    >
                        <StyleForm>
                            <InputModal
                                title="VEHICLE"
                                label="Vehicle Name"
                                name="VehicleName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.VehicleName}
                                error={!!touched.VehicleName && !!errors.VehicleName}
                                helperText={touched.VehicleName && errors.VehicleName}
                                dataSource={dataVehicle}
                                columns={columnsVehicle}
                                loading={isLoading}
                                propsSelecteRow={(values) => setSelectVehicle(values)}
                                propsOpenModal={(values) => setOpenModal(values)}
                            />

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Start Date*"
                                    value={StartDate}
                                    onBlur={handleBlur}
                                    onChange={(date) => setStartDate(date)}
                                    name="StartDate"
                                    error={!!touched.StartDate && !!errors.StartDate}
                                    helperText={touched.StartDate && errors.StartDate}
                                    sx={{ gridColumn: "span 1" }}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="End Date*"
                                    value={EndDate}
                                    onBlur={handleBlur}
                                    onChange={(date) => setEndDate(date)}
                                    name="EndDate"
                                    error={!!touched.EndDate && !!errors.EndDate}
                                    helperText={touched.EndDate && errors.EndDate}
                                    sx={{ gridColumn: "span 1" }}
                                />
                            </LocalizationProvider>

                        </StyleForm>

                        <Box
                            sx={{
                                display: "flex",
                                marginTop: 1,
                                px: 3,
                                gap: 1,
                                justifyContent: "flex-end",
                                alignItems: "center"
                            }}
                        >
                            <Button
                                type="button"
                                color="error"
                                variant="contained"
                                onClick={() => handleCancel(resetForm)}
                            >
                                <span>Cancel</span>
                            </Button>
                            <LoadingButton
                                color="primary"
                                onClick={handleSubmit}
                                loading={loading}
                                variant="contained"
                            >
                                <span>Submit</span>
                            </LoadingButton>
                        </Box>


                    </Box>
                )}
            </Formik>
        </>
    )
}

export default Filters;

const checkoutSchema = yup.object().shape({
    VehicleName: yup
        .string()
        .required("Please complete this Vehicle Name."),
});

const initialValues = {
    VehicleName: "",
};

const columnsVehicle = [
    {
        key: 'key',
        title: 'No',
        dataIndex: 'key',
        width: 80,
        fixed: 'left',
        sorter: (a, b) => a.key - b.key,
    },
    {
        title: 'Vehicle Code',
        dataIndex: 'VehicleCode',
        fixed: 'left',
        sorter: (a, b) => a.VehicleCode.localeCompare(b.VehicleCode),
    },
    {
        title: 'Vehicle Name',
        dataIndex: 'VehicleName',
        sorter: (a, b) => a.VehicleName.localeCompare(b.VehicleName),
    },
    {
        title: 'Facility Name',
        dataIndex: 'FacilityName',
        sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
    },
    {
        title: 'Model Type Name',
        dataIndex: 'ModelTypeName',
        sorter: (a, b) => a.ModelTypeName.localeCompare(b.ModelTypeName),
    },
    {
        title: 'No Pol',
        dataIndex: 'NoPol',
        sorter: (a, b) => a.NoPol.localeCompare(b.NoPol),
    },
    {
        title: 'Asset No',
        dataIndex: 'AssetNo',
        sorter: (a, b) => a.AssetNo.localeCompare(b.AssetNo),
    },
    {
        title: 'Purchase Date',
        dataIndex: 'PurchaseDate',
        sorter: (a, b) => a.PurchaseDate.localeCompare(b.PurchaseDate),
    },
    {
        title: 'Warranty Exp Date',
        dataIndex: 'WarrantyExpDate',
        sorter: (a, b) => a.WarrantyExpDate.localeCompare(b.WarrantyExpDate),
    },
    {
        title: 'Meter Reading',
        dataIndex: 'MeterReading',
        sorter: (a, b) => a.MeterReading - b.MeterReading,
    },
    {
        title: 'Vehicle Owner Name',
        dataIndex: 'VehicleOwnerName',
        sorter: (a, b) => a.VehicleOwnerName.localeCompare(b.VehicleOwnerName),
    },
]