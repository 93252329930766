import React, { useEffect, useRef, useState } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ButtonForm } from "../../global/components/ButtonAction";
import TabsSchedule from "./tab";
import TabMeter from "./meter";
import StyleContent from "../../../components/Global/StyleContent";
import StyleForm from "../../../components/Global/StyleContent/StyleForm";
import { getSchedule } from "../../../Api/Master/GetData";
import { postSchedule } from "../../../Api/Master/PostData";

const FormSchedule = () => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramsScheduleType = searchParams.get('type');

  const formRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [ScheduleCode, setScheduleCode] = useState("");

  const [scheduleInput, setScheduleInput] = useState("");
  const [scheduleTabs, setscheduleTabs] = useState("");
  const [scheduleDays, setScheduleDays] = useState([]);
  const [scheduleMonth, setScheduleMonth] = useState([]);

  const [selectedScheduleType, setSelectedScheduleType] = useState("");

  useEffect(() => {
    if (paramsScheduleType) {
      setSelectedScheduleType(paramsScheduleType);
      if (formRef.current) {
        formRef.current.setFieldValue("ScheduleType", paramsScheduleType);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsScheduleType]);

  const handleInputChange = (tab, value, days, checked) => {
    if (tab === "daily") {
      setscheduleTabs(tab);
      setScheduleInput(value);
      setScheduleDays([]);
      setScheduleMonth([]);
    } else if (tab === "weekly") {
      setscheduleTabs(tab);
      setScheduleInput(value);
      setScheduleMonth([]);
      setScheduleDays(days);
    } else if (tab === "monthly") {
      setscheduleTabs(tab);
      setScheduleInput(value);
      setScheduleDays(days);
      setScheduleMonth(checked);
    } else if (tab === "runtime") {
      setscheduleTabs(tab);
      setScheduleInput(value);
      setScheduleDays([]);
      setScheduleMonth([]);
    }
  };

  const FetchScheduleCode = async () => {
    try {
      const response = await getSchedule();
      if (response.length > 0) {
        const SCData = response.filter(
          (item) => item.ScheduleCode && item.ScheduleCode.startsWith("SC")
        );
        if (SCData.length > 0) {
          const lastCode = SCData[SCData.length - 1].ScheduleCode;
          const nextNumber = parseInt(lastCode.substr(2)) + 1;
          setScheduleCode(`SC${nextNumber.toString().padStart(4, "0")}`);
        } else {
          setScheduleCode("SC0001");
        }
      } else {
        setScheduleCode("SC0001");
      }
    } catch (error) {
      setScheduleCode("SC0001");
      console.log(error.response.statusText);
    }
  };

  useEffect(() => {
    FetchScheduleCode();
  }, []);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("ScheduleCode", ScheduleCode);
    }
  }, [ScheduleCode]);

  const handleScheduleSelection = (event, value) => {
    if (value) {
      setSelectedScheduleType(value.Name);
    } else {
      setSelectedScheduleType("");
    }
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      if (scheduleTabs === "runtime" && scheduleInput > 9999) {
        enqueueSnackbar("current cannot exceed 9999!", { variant: "warning" });
        setLoading(false);
        return;
      }

      const modifiedValues = {
        ...values,
        ScheduleCode: ScheduleCode,
        PeriodeType: scheduleTabs,
        OccursEvery: scheduleInput,
        Days: scheduleDays.join(","),
        Month: scheduleMonth.join(","),
      };
      const response = await postSchedule(modifiedValues);
      enqueueSnackbar(response.data.statusMessage, { variant: "success" });
      navigate("/master/schedule");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        const errorMessage = error.response.data.statusMessage;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } else {
        enqueueSnackbar("Data failed to save.", { variant: "error" });
      }
      console.error("error code", error.response);
    }
    setLoading(false);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={{ ...initialValues, ScheduleCode: ScheduleCode }}
      validationSchema={checkoutSchema}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Box>
          <StyleContent>
            <Header title="SCHEDULE" subtitle="Create a Schedule" />
            <StyleForm>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Schedule Code*"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  setScheduleCode(e.target.value);
                }}
                value={ScheduleCode}
                name="ScheduleCode"
                error={!!touched.ScheduleCode && !!errors.ScheduleCode}
                helperText={touched.ScheduleCode && errors.ScheduleCode}
                sx={{ gridColumn: "span 2" }}
                inputProps={{ maxLength: 6 }}
              />

              <Autocomplete
                fullWidth
                disablePortal
                options={ScheduleType}
                getOptionLabel={(option) => option.Name}
                sx={{ gridColumn: "span 2" }}
                onChange={(event, value) => {
                  handleScheduleSelection(event, value);
                  setFieldValue("ScheduleType", value ? value.Name : "");
                }}
                value={
                  ScheduleType.find(
                    (option) => option.Name === selectedScheduleType
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Schedule Type*"
                    onBlur={handleBlur}
                    error={!!touched.ScheduleType && !!errors.ScheduleType}
                    helperText={touched.ScheduleType && errors.ScheduleType}
                  />
                )}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Schedule Name*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.ScheduleName}
                name="ScheduleName"
                error={!!touched.ScheduleName && !!errors.ScheduleName}
                helperText={touched.ScheduleName && errors.ScheduleName}
                sx={{ gridColumn: "span 2" }}
                autoFocus
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Description}
                name="Description"
                sx={{ gridColumn: "span 2" }}
                multiline
                rows={4}
              />
            </StyleForm>
            {selectedScheduleType === "Meter" ? (
              <TabMeter onInputChange={handleInputChange} />
            ) : (
              <TabsSchedule onInputChange={handleInputChange} />
            )}
          </StyleContent>

          <ButtonForm
            handleSubmit={handleSubmit}
            loading={loading}
          />
        </Box>
      )}
    </Formik>
  );
};

const checkoutSchema = yup.object().shape({
  ScheduleCode: yup
    .string()
    .max(10, "Maximum of 10 characters allowed")
    .required("Please complete this Schedule Code."),
  ScheduleName: yup
    .string()
    .max(50, "Maximum of 50 characters allowed")
    .required("Please complete this Schedule Name."),
  ScheduleType: yup.string().required("Please complete this Schedule Type."),
});
const initialValues = {
  ScheduleCode: "",
  ScheduleName: "",
  ScheduleType: "",
  Description: "",
};

const ScheduleType = [
  {
    Name: "Calendar",
  },
  {
    Name: "Meter",
  },
];

export default FormSchedule;
