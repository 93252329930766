import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { Box } from "@mui/material";
import { Table } from "antd";
import SearchData from "../../global/components/FeatureTable/SearchData";
import StyleContent from "../../../components/Global/StyleContent";
import { getWOStatus } from "../../../Api/Report/GetData";
import dayjs from "dayjs";


const WorkOrderStatus = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getWOStatus();
        setData(res);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
    setIsLoading(false);
  }, []);

  const columns = [
    {
      title: 'No',
      dataIndex: 'key',
      width: 100,
      fixed: 'left',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'Work Order Number',
      dataIndex: 'WONumber',
      sorter: (a, b) => a.WONumber.localeCompare(b.WONumber),
    },
    {
      title: 'Work Order Date',
      dataIndex: 'WODate',
      sorter: (a, b) => a.WODate.localeCompare(b.WODate),
      render: (date) => <p>{dayjs(date).format('YYYY-MM-DD')}</p>
    },
    {
      title: 'WO Request Number',
      dataIndex: 'WORNumber',
      sorter: (a, b) => a.WORNumber.localeCompare(b.WORNumber),
    },
    {
      title: 'Preference Type',
      dataIndex: 'WOType',
      sorter: (a, b) => a.WOType.localeCompare(b.WOType),
    },
    {
      title: 'Preference Name',
      dataIndex: 'TranTypeRefName',
      sorter: (a, b) => a.TranTypeRefName.localeCompare(b.TranTypeRefName),
    },
    {
      title: 'Facility Name',
      dataIndex: 'FacilityName',
      sorter: (a, b) => a.FacilityName.localeCompare(b.FacilityName),
      render: (value) => value ? value : '-'
    },
    {
      title: 'Building Name',
      dataIndex: 'BuildingName',
      sorter: (a, b) => a.BuildingName.localeCompare(b.BuildingName),
      render: (value) => value ? value : '-'
    },
    {
      title: 'Location Name',
      dataIndex: 'LocationName',
      sorter: (a, b) => a.LocationName.localeCompare(b.LocationName),
    },
    {
      title: 'Due Date',
      dataIndex: 'DueDateWO',
      sorter: (a, b) => a.DueDateWO.localeCompare(b.DueDateWO),
      render: (date) => <p>{dayjs(date).format('YYYY-MM-DD')}</p>
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      sorter: (a, b) => a.Status.localeCompare(b.Status),
      render: (value) =>
        value ? value : '-'
    },
  ];

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some((val) =>
      val && val.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  return (
    <StyleContent>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Header
          title="WORK ORDER STATUS"
          subtitle="All Work Order Status"
        />
      </Box>

      <SearchData
        filterValue={searchText}
        handleFilterChange={handleSearch}
      />

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filteredData}
        // rowSelection={data.length === 0 ? null : rowSelection}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        scroll={{ x: 'max-content' }}
      />

    </StyleContent>
  );
};

export default WorkOrderStatus